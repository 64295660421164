import React, { useEffect, useState } from "react";
import service from '../../service/apiService'
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from 'primereact/row'
import { TabView, TabPanel } from "primereact/tabview";
import moment from "moment";
import { formatToTwoDigits } from "../../utilities/utillFunction";
import FailureListing from "./FailureLisiting";
import ListingTotal from "./ListingTotal";
const keyMapping = {
    'Amazon_domestic_eligible_fail': 'Amazon_Domestic_eligible_fail',
    'Amazon_domestic_eligible_succ': 'Amazon_domestic_eligible_succ',
    'Amazon_domestic_listing_fail': 'Amazon_domestic_listing_fail',
    'Amazon_domestic_listing_succ': 'Amazon_domestic_listing_succ',
    'Amazon_international_eligible_fail': 'Amazon_international_eligible_fail',
    'Amazon_international_eligible_succ': 'Amazon_international_eligible_succ',
    'Amazon_international_listing_fail': 'Amazon_international_listing_fail',
    'Amazon_international_listing_succ': 'Amazon_international_listing_succ',
    'Bookscape_domestic_eligible_fail': 'Bookscape_domestic_eligible_fail',
    'Bookscape_domestic_eligible_succ': 'Bookscape_domestic_eligible_succ',
    'Bookscape_domestic_listing_fail': 'Bookscape_domestic_listing_fail',
    'Bookscape_domestic_listing_succ': 'Bookscape_domestic_listing_succ',
    'Bookscape_international_eligible_fail': 'Bookscape_international_eligible_fail',
    'Bookscape_international_eligible_succ': 'Bookscape_international_eligible_succ',
    'Bookscape_international_listing_fail': 'Bookscape_international_listing_fail',
    'Bookscape_international_listing_succ': 'Bookscape_international_listing_succ',
    'FlipkartHSP_domestic_eligible_fail': 'FlipkartHSP_domestic_eligible_fail',
    'FlipkartHSP_international_eligible_fail': 'FlipkartHSP_international_eligible_fail',
    'FlipkartLSP_domestic_eligible_fail': 'FlipkartLSP_domestic_eligible_fail',
    'FlipkartLSP_international_eligible_fail': 'FlipkartLSP_international_eligible_fail',
}

const Listing = () => {
    const serviceId = localStorage.getItem('serviceId')
    const [forPeriod, setForPeriod] = useState([])
    const [today, setToday] = useState([])
    const [yesterday, setYesterday] = useState([])
    const [activeIndex, setActiveIndex] = useState(0)
    const [lastPeriod, setLastPeriod] = useState(null)
    const [todayInfoMsg, setTodayInfoMsg] = useState(null)
    const [yesterdayInfoMsg, setYesterdayMsg] = useState(null)

    function rename(arr) {
        arr = arr.map(function (obj) {
            Object.keys(keyMapping).map(k => {
                // Assign new key
                obj[keyMapping[k]] = obj[k];
            })
            // Delete old key
            delete obj['Burma'];

            return obj;
        });
        return arr
    }

    const getCollection = (type) => {
        let collection = {}
        if (type === "forPeriod") {
            collection = {
                'listing': 'nq-dashboard-b2c-listing-period-meta',
                'ingested': 'nq-dashboard-b2c-ingestion-by-partner-period-meta',
                'sentToPO': 'nq-dashboard-b2c-SentToPO-by-partner-period-meta'
            }
        }
        else if (type === "today" || type === "yesterday") {
            collection = {
                'listing': 'nq-dashboard-b2c-listing',
                'ingested': 'nq-dashboard-b2c-ingestion-by-partner',
                'sentToPO': 'nq-dashboard-b2c-SentToPO-by-partner'
            }
        }
        return collection
    }

    const getData = (date, type) => {
        const collections = getCollection(type)
        let ingested_query = {
            operation: "aggregate",
            aggregate: [
                { $group: { _id: '$categoryType', count: { "$sum": "$Count" } } }
            ]
        }
        let setToPO_query = {
            operation: "aggregate",
            aggregate: [
                { $group: { _id: { 'categoryType': '$categoryType', 'fdstatus': "$fdstatus" }, count: { "$sum": "$Count" } } }
            ]
        }
        let payload = {
            filter: {}
        }

        if (date) {
            ingested_query.aggregate.unshift({ $match: { date: date } })
            setToPO_query.aggregate.unshift({ $match: { date: date } })
            payload.filter = { date: date }
        }
        Promise.all([
            service.httpDBService(ingested_query, serviceId, collections.ingested),
            service.httpDBService(setToPO_query, serviceId, collections.sentToPO),
            service.httpDBService(payload, serviceId, collections.listing)
        ])
            .then((res) => {
                let categories = ['International', 'Domestic', 'Total']
                let ecommerse = ['Amazon', 'Flipkart', 'Bookscape']
                console.log("res-------->", res);
                if (!res[0].length && !res[1].length && !res[2].length) {
                    if (type == "today") setTodayInfoMsg("Data is not available for the day")
                    else if (type == "yesterday") setYesterdayMsg("Data is not available")
                    return
                }

                let ingested = res[0], ingested_map = {}, ingested_total = 0
                let sent_to_po = res[1], sent_to_po_map = {}, sentToPO_fail_total = 0, sentToPO_succ_total = 0, listing_data = {}
                if (res[2].length) {
                    listing_data = res[2][0]
                    if (!date) {
                        let dt_obj = { from: listing_data.date + " " + formatToTwoDigits(listing_data.start_hr) + ":00:00", to: listing_data.date + " " + formatToTwoDigits(listing_data.end_hr) + ":00:00" }
                        setLastPeriod(dt_obj)
                    }
                }
                ingested.forEach(i => {
                    ingested_map[i._id] = i.count
                    ingested_total += i.count
                    sent_to_po_map[i._id] = { 'failed': 0, 'success': 0 }
                })

                ingested_map['Total'] = ingested_total

                if (sent_to_po.length) {
                    sent_to_po.forEach(s => {
                        if (s._id.fdstatus == 'Failed') {
                            sentToPO_fail_total += s.count
                            if (sent_to_po_map[s._id.categoryType] && Object.keys(sent_to_po_map[s._id.categoryType]).length) sent_to_po_map[s._id.categoryType]['failed'] = s.count || 0
                            else sent_to_po_map[s._id.categoryType] = { 'failed': s.count || 0 }
                        }
                        if (s._id.fdstatus == 'Send for Listing') {
                            sentToPO_succ_total += s.count

                            if (sent_to_po_map[s._id.categoryType] && Object.keys(sent_to_po_map[s._id.categoryType]).length) sent_to_po_map[s._id.categoryType]['success'] = s.count || 0
                            else sent_to_po_map[s._id.categoryType] = { 'success': s.count || 0 }
                        }
                    })
                }
                sent_to_po_map['Total'] = { 'failed': sentToPO_fail_total, 'success': sentToPO_succ_total }

                let arr = []
                let amazon = { eli_failure: 0, eli_sucsess: 0, list_failure: 0, list_success: 0 },
                    flipkart = { eli_failure: 0, eli_sucsess: 0, list_failure: 0, list_success: 0 },
                    bookscape = { eli_failure: 0, eli_sucsess: 0, list_failure: 0, list_success: 0 }

                categories.forEach(c => {
                    ecommerse.forEach(e => {
                        let new_e = e
                        if (e == 'Flipkart') new_e = 'FlipkartLSP'
                        let obj = {
                            'category': c, ecommerce: e, ingested: ingested_map[c], sentToPO_failure: sent_to_po_map[c].failed, sentToPO_success: sent_to_po_map[c].success, 'success_6hr': "",
                            eli_failure: 0, eli_sucsess: 0, list_failure: 0, list_success: 0
                        }

                        if (c !== 'Total') {
                            if (new_e === 'Amazon') {
                                amazon.eli_failure += listing_data[new_e + "_" + c.toLocaleLowerCase() + "_eligible_fail"] || 0
                                amazon.eli_sucsess += listing_data[new_e + "_" + c.toLocaleLowerCase() + "_eligible_succ"] || 0
                                amazon.list_failure += listing_data[new_e + "_" + c.toLocaleLowerCase() + "_listing_fail"] || 0
                                amazon.list_success += listing_data[new_e + "_" + c.toLocaleLowerCase() + "_listing_succ"] || 0
                            }
                            if (new_e === 'FlipkartLSP') {
                                flipkart.eli_failure += listing_data[new_e + "_" + c.toLocaleLowerCase() + "_eligible_fail"] || 0
                                flipkart.eli_sucsess += listing_data[new_e + "_" + c.toLocaleLowerCase() + "_eligible_succ"] || 0
                                flipkart.list_failure += listing_data[new_e + "_" + c.toLocaleLowerCase() + "_listing_fail"] || 0
                                flipkart.list_success += listing_data[new_e + "_" + c.toLocaleLowerCase() + "_listing_succ"] || 0
                            }
                            if (new_e === 'Bookscape') {
                                bookscape.eli_failure += listing_data[new_e + "_" + c.toLocaleLowerCase() + "_eligible_fail"] || 0
                                bookscape.eli_sucsess += listing_data[new_e + "_" + c.toLocaleLowerCase() + "_eligible_succ"] || 0
                                bookscape.list_failure += listing_data[new_e + "_" + c.toLocaleLowerCase() + "_listing_fail"] || 0
                                bookscape.list_success += listing_data[new_e + "_" + c.toLocaleLowerCase() + "_listing_succ"] || 0
                            }

                            obj['eli_failure'] = listing_data[new_e + "_" + c.toLocaleLowerCase() + "_eligible_fail"] || 0
                            obj['eli_sucsess'] = listing_data[new_e + "_" + c.toLocaleLowerCase() + "_eligible_succ"] || 0
                            obj['list_failure'] = listing_data[new_e + "_" + c.toLocaleLowerCase() + "_listing_fail"] || 0
                            obj['list_success'] = listing_data[new_e + "_" + c.toLocaleLowerCase() + "_listing_succ"] || 0
                        }

                        if (c === "Total") {
                            if (new_e === 'Amazon') obj = Object.assign(obj, amazon)
                            if (new_e === 'FlipkartLSP') obj = Object.assign(obj, flipkart)
                            if (new_e === 'Bookscape') obj = Object.assign(obj, bookscape)
                        }
                        arr.push(obj)
                    })
                })
                // console.log("array-------------->", type, arr)
                if (type == 'forPeriod') setForPeriod(arr)
                else if (type == 'today') setToday(arr)
                else if (type == 'yesterday') setYesterday(arr)
            })
    }

    useEffect(() => {
        let collection = {
            'listing': 'nq-dashboard-b2c-listing-period-meta',
            'ingested': 'nq-dashboard-b2c-ingestion-by-partner-period-meta',
            'sentToPO': 'nq-dashboard-b2c-SentToPO-by-partner-period-meta'
        }
        getData(null, 'forPeriod')
    }, [])

    const customHeader = (
        <ColumnGroup>
            <Row >
                <Column headerClassName="bg-primary" header="" colSpan={3} className="text-center"></Column>
                <Column headerClassName="bg-primary" header="Sent To PO" colSpan={2} className="text-center"></Column>
                <Column headerClassName="bg-primary" header="Eligibilty" colSpan={2} className="text-center"></Column>
                <Column headerClassName="bg-primary" header="Listing" colSpan={2} className="text-center"></Column>
                {/* <Column headerClassName="bg-primary" header="" className="text-center"></Column> */}
            </Row>
            <Row>
                <Column headerClassName="bg-primary" header="" className="text-center" ></Column>
                <Column headerClassName="bg-primary" header="" className="text-center" ></Column>
                <Column headerClassName="bg-primary" header="Ingested" className="text-center" ></Column>
                <Column headerClassName="bg-primary" header="Failure" className="text-center" ></Column>
                <Column headerClassName="bg-primary" header="Success" className="text-center" ></Column>
                <Column headerClassName="bg-primary" header="Failure" className="text-center" ></Column>
                <Column headerClassName="bg-primary" header="Success" className="text-center" ></Column>
                <Column headerClassName="bg-primary" header="Failure" className="text-center" ></Column>
                <Column headerClassName="bg-primary" header="Success" className="text-center" ></Column>
                {/* <Column headerClassName="bg-primary" header=" % Success (6hrs)" className="text-center" ></Column> */}
            </Row>
        </ColumnGroup>
    )

    const rowSpan = (rowData, data) => {
        // console.log(data)
        // if(data.field == 'sentToPO_failure') console.log('********************',data.rowIndex, data.props.value[data.rowIndex - 1] )

        if (data.rowIndex % 3 === 0) {
            // || rowData[data.field] !== data.props.value[data.rowIndex - 1][data.field]) {
            return (
                <>{rowData[data.field]}</>
            );
        }
        else return null;
    }

    const TableView = (data) => {
        // console.log("table view ", data)
        return (
            <div>
                <DataTable value={data} responsiveLayout="scroll" rowGroupMode="rowspan" groupRowsBy={["category", "ingested", "sentToPO_failure", "sentToPO_success"]} showGridlines headerColumnGroup={customHeader}>
                    <Column headerClassName="bg-primary" className="text-center" field='category' body={rowSpan}></Column>
                    <Column headerClassName="bg-primary" className="text-center" field='ecommerce'></Column>
                    <Column headerClassName="bg-primary" className="text-center" field='ingested' body={rowSpan}></Column>
                    <Column headerClassName="bg-primary" className="text-center" field='sentToPO_failure' body={rowSpan}></Column>
                    <Column headerClassName="bg-primary" className="text-center" field='sentToPO_success' body={rowSpan}></Column>
                    <Column headerClassName="bg-primary" className="text-center" field='eli_failure'></Column>
                    <Column headerClassName="bg-primary" className="text-center" field='eli_sucsess'></Column>
                    <Column headerClassName="bg-primary" className="text-center" field='list_failure'></Column>
                    <Column headerClassName="bg-primary" className="text-center" field='list_success'></Column>
                    {/* <Column headerClassName="bg-primary" className="text-center" field='success_6hr'></Column> */}
                </DataTable>
            </div>
        )
    }

    const onTabChange = (e) => {
        if (e.index === 1 || e.index === 2) {
            let dt = moment().format("YYYY-MM-DD")
            let type = 'today'
            if (e.index === 2) {
                dt = moment().subtract(1, "days").format("YYYY-MM-DD")
                type = 'yesterday'
            }
            if ((e.index === 1 && !today.length) || (e.index === 2 && !yesterday.length)) getData(dt, type)
        }
        setActiveIndex(e.index)
    }

    return (
        <>
            <div>
                <h4>Listing</h4>
            </div>
            <TabView activeIndex={activeIndex} onTabChange={onTabChange}>
                <TabPanel header="For The Period">
                    {lastPeriod ?
                        <>
                            <div className="displayCard bg-white mb-2">
                                {"From:  " + lastPeriod.from + " To : " + lastPeriod.to}
                            </div>

                        </> : null}
                    {forPeriod.length ? TableView(forPeriod) : null}
                </TabPanel>
                <TabPanel header="For The Day">
                    {today.length ? TableView(today) : todayInfoMsg ?
                        <div className="my-info-message">{todayInfoMsg}</div> : null
                    }
                </TabPanel>
                <TabPanel header="Previous Day">
                    {yesterday.length ? TableView(yesterday) : yesterdayInfoMsg ?
                        <div className="my-info-message">{yesterdayInfoMsg}</div> : null
                    }
                </TabPanel>
            </TabView>

            <FailureListing />
            <div>
                <h4>Listing - Last 10 days</h4>
            </div>

            <ListingTotal />
        </>
    )
}

export default Listing;