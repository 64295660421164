import React, { useEffect, useState } from "react";
import service from '../../service/apiService'
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from 'primereact/row'
import { TabView, TabPanel } from "primereact/tabview";
import moment from "moment";

const FailureListing = () => {
    const serviceId = localStorage.getItem('serviceId')
    const [forPeriod, setForPeriod] = useState([])
    const [today, setToday] = useState([])
    const [yesterday, setYesterday] = useState([])
    const [activeIndex, setActiveIndex] = useState(0)

    const getCollection = (type) => {
        let collection = {}
        if (type === "forPeriod") {
            collection = "nq-dashboard-b2c-listing-errors-period-meta"
        }
        else if (type === "today" || type === "yesterday") {
            collection = "nq-dashboard-b2c-listing-errors"
        }
        return collection
    }

    const getData = (date, type) => {
        const collection = getCollection(type)

        let payload = {
            projection: {
                "_id": 0
            }
        }

        if (date) {
            payload.filter = { date: date }
        }
        service.httpDBService(payload, serviceId, collection).then((res) => {
            if (!res || (res && !res.length)) return

            let arr = []
            if (res[0]?.CombinedErrors?.length) {
                res[0].CombinedErrors.forEach((e) => {
                    let temp = {
                        errorCode: e.code,
                        count: e.count
                    }
                    arr.push(temp)
                })
            }
            if (type == 'forPeriod') setForPeriod(arr)
            else if (type == 'today') setToday(arr)
            else if (type == 'yesterday') setYesterday(arr)
        })
    }

    useEffect(() => {
        getData(null, 'forPeriod')
    }, [])

    const customHeader = (
        <ColumnGroup>
            <Row >
                <Column headerClassName="bg-primary" header="Failure Reason" className="text-center"></Column>
                <Column headerClassName="bg-primary" header="Count" className="text-center"></Column>
            </Row>
        </ColumnGroup>
    )

    const rowSpan = (rowData, data) => {
        // console.log(data)
        // if(data.field == 'sentToPO_failure') console.log('********************',data.rowIndex, data.props.value[data.rowIndex - 1] )

        if (data.rowIndex % 3 === 0) {
            // || rowData[data.field] !== data.props.value[data.rowIndex - 1][data.field]) {
            return (
                <>{rowData[data.field]}</>
            );
        }
        else return null;
    }

    const TableView = (data) => {
        // console.log("table view ", data)
        return (
            <div>
                <DataTable value={data} responsiveLayout="scroll" showGridlines headerColumnGroup={customHeader}>
                    <Column headerClassName="bg-primary" className="text-center" field='errorCode'></Column>
                    <Column headerClassName="bg-primary" className="text-center" field='count'></Column>
                </DataTable>
            </div>
        )
    }

    const onTabChange = (e) => {
        if (e.index === 1 || e.index === 2) {
            let dt = moment().format("YYYY-MM-DD")
            let type = 'today'
            if (e.index === 2) {
                dt = moment().subtract(1, "days").format("YYYY-MM-DD")
                type = 'yesterday'
            }
            if((e.index === 1 && !today.length) || (e.index === 2 && !yesterday.length)) getData(dt, type)
        }
        setActiveIndex(e.index)
    }

    return (
        <>
            <div className="display-card">
                <h4>Listing Failure (Amazon)</h4>
            </div>
            <TabView activeIndex={activeIndex} onTabChange={onTabChange}>
                <TabPanel header="For The Period">
                    {forPeriod.length ? <div className="lg:col-6 col-12">
                        {TableView(forPeriod)}
                    </div> : null}
                </TabPanel>
                <TabPanel header="For The Day">
                    {today.length ? <div className="lg:col-6 col-12">{TableView(today)}</div> : <div className="my-info-message">{"Data is not available for the day"}</div>}
                </TabPanel>
                <TabPanel header="Previous Day">
                    {yesterday.length ? <div className="lg:col-6 col-12">{TableView(yesterday)} </div> : <div className="my-info-message">{"Data is not available"}</div>}
                </TabPanel>
            </TabView>
        </>
    )
}

export default FailureListing;